import { CustomCellRendererProps } from "ag-grid-react";
import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { ManualChargeConstant } from "./ManualChargeConstant";

const MAN_CHG_HDR_CONSTANT = ManualChargeConstant.Header;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_MAN_CHARGE_HDR_COL_DEF: any[] = [
    {
        headerName: MAN_CHG_HDR_CONSTANT.STATE,
        field: 'hdrState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.REF_NO,
        field: 'manChargeNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.CNTR_NO,
        field: 'cntrNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
        dataType: "date"
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.CONS_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.VSL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.VOY_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.VSL_NAME,
        field: 'vesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.SERVICE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.ARR_DATETIME,
        field: 'etb',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "dateTime"
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.DEP_DATETIME,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "dateTime"
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.YOUR_REF,
        field: 'yourRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.OUR_REF,
        field: 'ourRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.PO_NO,
        field: 'poNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.LOA,
        field: 'loa',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 160,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.SOA,
        field: 'soa',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.SL_VSL_CODE,
        field: 'slVesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.SL_IB_VOY_CODE,
        field: 'slIbVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.SL_OB_VOY_CODE,
        field: 'slObVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.INV_NO,
        field: 'invoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.CREDIT_NOTE_NO,
        field: 'creditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 170,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.DRAFT_INV_NO,
        field: 'draftInvoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.DRAFT_CREDIT_NOTE_NO,
        field: 'draftCreditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.CREATED_BY,
        field: 'createdBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.CREATED_DATE,
        field: 'createdDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.MODIFIED_BY,
        field: 'updatedBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 140,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.MODIFIED_DATE,
        field: 'updatedDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    cellRenderers['hdrState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    externalFnctions['hdrState'] = calculateStateColor;

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}